import React, { useEffect, useMemo, useState } from "react";
import Page from "./Page";
import { useSearchParams } from "react-router-dom";
import { downloadImages } from "./downloadImages";
import useFetch from "./useFetch";
import { SelfieResult, shouldSendMatch } from "./SearchResults";
import LoadingSection from "./LoadingSection";
import mobile from "is-mobile";
import { USER_ROLE } from "./Login";
import { toastSuccess } from "./toast";
import SelfiePics from "./SelfiePics";

const MyPicsPage = ({}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const eventSlug = searchParams.get("event");

  const [selfie, setSelfie] = useState();

  const { fetchData, data, error, loading } = useFetch();

  useEffect(() => {
    fetchData({
      query: "getSelfieByGuest",
      params: {
        token,
        eventSlug,
      },
    });
  }, []);

  useEffect(() => {
    if (data?.selfie) {
      setSelfie(data.selfie);
    }
  }, [data]);

  const cleanWrongMatch = (_, albumImageKey, remove) => {
    // Optimistic response
    setSelfie({
      ...selfie,
      matches:
        selfie.matches?.map((match) =>
          match.albumImage.key === albumImageKey
            ? { ...match, isRemoved: remove, isAdded: !remove }
            : match
        ) || [],
    });

    fetch(
      `${
        process.env.REACT_APP_SERVER_ENDPOINT
      }/cleanWrongMatchByGuest?token=${token}&selfieId=${
        selfie._id
      }&albumImageKey=${albumImageKey}&revert=${!remove}`
    )
      .then(async (res) => {})
      .catch((err) => console.log("CLIENT error", err))
      .finally(() => {});
  };

  const { loading: sendingImages, fetchData: sendMatches } = useFetch({
    onSuccess: (resJson) => {
      const { imagesSentCount, selfie: _newSelfie } = resJson || {};
      setSelfie({ ...selfie, ..._newSelfie });
      toastSuccess(`נשלחו ${imagesSentCount} תמונות, כמה דקות והן אצלך!`);
    },
  });

  const canUserGetMessages = useMemo(() => {
    const userMessagesDates = selfie?.messageLog
      ?.filter(({ log }) => log.includes("User sent"))
      .map(({ timestamp }) => new Date(timestamp));
    const latestUserMessageDate = new Date(
      Math.max(...(userMessagesDates || []))
    );
    return (
      (new Date() - new Date(latestUserMessageDate)) / (1000 * 60 * 60) <= 24
    );
  }, [selfie]);

  const onSendMatchesClick = () => {
    if (imagesToSend > 50) {
      const isConfirmed = window.confirm(
        `לידיעתכם: וואטסאפ מגביל את קצב שליחת התמונות ל-10 תמונות בדקה.
ככל שתבחרו לשלוח יותר תמונות, זמן השליחה יתארך בהתאם.
אנא התאזרו בסבלנות🙂`
      );
      if (!isConfirmed) {
        return;
      }
    }

    if (canUserGetMessages) {
      sendMatches({
        query: "sendMatchesToWhatsappByGuest",
        params: {
          selfieId: selfie._id,
          token,
        },
      });
    } else {
      window.open(
        "https://api.whatsapp.com/send?phone=972555530749&text=היי, אשמח לקבל את התמונות שלי בוואטסאפ!",
        "_blank"
      );
    }
  };

  const imagesToSend = useMemo(
    () =>
      selfie?.matches
        ?.sort((a, b) =>
          a.distance === b.distance ? 0 : a.distance < b.distance ? -1 : 1
        )
        .filter(({ isAdded, isSent, isRemoved }, index) => {
          return (
            !isSent &&
            shouldSendMatch({
              isRemoved,
              isAdded,
              isSent,
              index,
            })
          );
        }).length,
    [selfie]
  );

  const imagesToDownload = useMemo(
    () =>
      selfie?.matches
        ?.sort((a, b) =>
          a.distance === b.distance ? 0 : a.distance < b.distance ? -1 : 1
        )
        .filter(({ isAdded, isRemoved }, index) => {
          return shouldSendMatch({
            isRemoved,
            isAdded,
            index,
          });
        }),
    [selfie]
  );

  const onDownloadImagesClick = () => {
    console.log("imagesToDownload", imagesToDownload);
    const imageUrls = [
      "https://picme-albums.s3.eu-central-1.amazonaws.com/event_66e2298cbfb040b548a8e996/album/5948522_DSC_9121.jpeg",
    ];

    // const imageUrls = imagesToDownload
    //   .map((img) => img.albumImage.url)
    //   .splice(0, 2);
    console.log("imageUrls", imageUrls);

    // const index = 0;
    // fetch(
    //   `${process.env.REACT_APP_SERVER_ENDPOINT}/proxy?url=${encodeURIComponent(imageUrls[0])}`,
    //   {
    //     method: "get",
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem(LOGIN_TOKEN_KEY)}`,
    //     },
    //   }
    // )
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // handle blob as in your original code
    //     const link = document.createElement("a");
    //     const url = window.URL.createObjectURL(blob);
    //     link.href = url;
    //     link.download = `PicMe_pic_${index}.jpg`; // Filename for the downloaded image
    //     link.click();
    //     window.URL.revokeObjectURL(url); // Clean up the URL object
    //   });

    toastSuccess(`החלה הורדה של התמונות`);

    imageUrls.forEach((imageUrl, index) =>
      // Fetch the image as a blob and trigger the download
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `PicMe_pic_${index}.jpg`; // Filename for the downloaded image
          link.click();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        })
        .catch(() => console.log("Failed to download image"))
    );
  };
  const userRole = useMemo(() => localStorage.getItem(USER_ROLE), []);

  if (loading) {
    return (
      <Page>
        <LoadingSection />
      </Page>
    );
  }

  return (
    <Page>
      <div className="page-section-padding">
        {error && <div className={"center red-text"}>{error}</div>}

        {selfie ? <MyPicsPageTop selfie={selfie} /> : null}
        {userRole === "super_admin" &&
          selfie &&
          imagesToDownload?.length > 0 && (
            <button
              className={`${mobile() ? "full-width font-xl" : "font-l"} mb24`}
              onClick={onDownloadImagesClick}
            >
              <>
                <span className="pl-text">הורד</span>
                <span className="pl-text">{imagesToDownload.length}</span>
                <span>תמונות</span>
              </>
            </button>
          )}
        {selfie && imagesToSend ? (
          <button
            className={`${
              mobile() ? "font-xl" : "font-l"
            } d-flex align-items-center justify-content-center mobile-hover-button`}
            onClick={onSendMatchesClick}
            disabled={sendingImages}
          >
            {sendingImages ? (
              <>
                <span className="pl-text">שולח...</span>
                <span className="mr12 loading-circle"></span>
              </>
            ) : (
              <>
                <span className="pl-text">שלח אליי</span>
                <span className="pl-text">{imagesToSend}</span>
                <span>תמונות לוואטסאפ</span>
              </>
            )}
          </button>
        ) : null}

        <div className="page-content d-flex-column">
          {selfie ? (
            <SelfiePics selfie={selfie} cleanWrongMatch={cleanWrongMatch} />
          ) : null}
        </div>
      </div>
    </Page>
  );
};

const MyPicsPageTop = ({ selfie }) => {
  return (
    <div
      className={
        "d-flex-column align-items-center justify-content-center p16 mb24 border-radius"
      }
      style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
    >
      <div className="center pb16 font-xl purple-dark-text">
        <span className="pl-text">התמונות שלך מהאירוע</span>

        {selfie.eventName ? (
          <>
            <span className="pl-text">של</span>
            <div className="bold">{selfie.eventName}</div>
          </>
        ) : null}
      </div>

      <div className="center font-xl purple-dark-text thin">
        <span className="pl-text">
          בחר.י את התמונות היפות ביותר שלך ושלח.י אותן ישירות לוואטסאפ
        </span>
      </div>

      <div className="center font-xl purple-dark-text thin pt32">
        <span className="pl-text">
        שימו לב: סלפי עם פנים לא ישרות עשוי לפגוע בתוצאות החיפוש. אם אינכם מופיעים בתוצאות, ניתן לשלוח סלפי נוסף עם פנים ישרות לבוט בוואטסאפ.
        </span>
      </div>
    </div>
  );
};

export default MyPicsPage;
